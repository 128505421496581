define(function (require) {
	if(typeof nexusVersion !== 'undefined' && nexusVersion !== "dev") {
		newrelic.addRelease('nexus-ui', nexusVersion + "-" + nexusBuildTimestamp);
	}

	require(['angularApp'], function() {
		// TODO remove when patient layout will be moved to nexus-ui-core library with mini flower chart in the patient sidebar
		require(['modules/flowerchart/flowerchart.module'], function() {});
		require(['modules/statechart/statechart.module'], function() {});
	});
});
